import { dom, library } from "@fortawesome/fontawesome-svg-core";

import {
  faDribbble,
  faFacebook,
  faGithub,
  faGoogle,
  faLinkedin,
  faStackOverflow,
  faTwitter,
  faVimeo,
} from "@fortawesome/free-brands-svg-icons";

import {
  faBrush,
  faClock,
  faCogs,
  faComments,
  faDatabase,
  faDragon,
  faExclamation,
  faExpand,
  faEye,
  faFilter,
  faFire,
  faFlask,
  faGavel,
  faGlobe,
  faHotdog,
  faImages,
  faKey,
  faMagic,
  faPaperclip,
  faRandom,
  faRobot,
  faServer,
  faSpinner,
  faSun,
  faTags,
  faTh,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faDribbble,
  faFacebook,
  faGithub,
  faGoogle,
  faLinkedin,
  faStackOverflow,
  faTwitter,
  faVimeo,
);

library.add(
  faBrush,
  faClock,
  faCogs,
  faComments,
  faDatabase,
  faDragon,
  faExclamation,
  faExpand,
  faEye,
  faFilter,
  faFire,
  faFlask,
  faGavel,
  faGlobe,
  faHotdog,
  faImages,
  faKey,
  faMagic,
  faPaperclip,
  faRandom,
  faRobot,
  faServer,
  faSpinner,
  faSun,
  faTags,
  faTh,
  faUpload,
);

dom.watch();
